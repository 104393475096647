'use client';

import { GTMUserType } from '@akinon/next/types';
import { IGTMEvent, DataLayerProduct, IProduct } from '@theme/types';
import dayjs from 'dayjs';

export const pushPageView = (url: string) => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'pageView',
    page: url
  });
};

export const pushAddToCart = (
  product: Pick<
    IProduct,
    | 'name'
    | 'pk'
    | 'price'
    | 'currency_type'
    | 'attributes'
    | 'discount_amount'
    | 'voucher_code'
    | 'quantity'
  >
) => {
  window.dataLayer.push({ ecommerce: null });
  pushEvent({
    Action: 'Browse',
    Label: 'Product Add to Cart',
    event: 'add_to_cart',
    ecommerce: {
      currency: product?.currency_type,
      value: Number(product?.price),
      items: {
        item_id: product?.pk,
        item_name: product?.name,
        affiliation: 'ZsaZsaZsu',
        coupon: product?.voucher_code,
        discount: product?.discount_amount,
        index: 0,
        item_brand: 'ZsaZsaZsu',
        ...(product?.attributes?.category_path &&
        product?.attributes?.category_path?.length > 0
          ? product?.attributes?.category_path
              .split(',')
              .reduce((acc, category, index) => {
                acc[`item_category${index + 1}`] = category.trim();
                return acc;
              }, {})
          : {}),
        item_list_id: product?.attributes?.ent_ProductHierarchyLevel01,
        item_list_name: product?.attributes?.ent_ProductHierarchyLevel02,
        item_variant: product?.attributes?.ent_ColorDescription,
        price: Number(product?.price),
        quantity: product?.quantity ? product.quantity : 1
      }
    }
  });
};

export const pushRemoveFromCart = (
  product: Pick<
    DataLayerProduct,
    | 'name'
    | 'pk'
    | 'price'
    | 'currency_type'
    | 'attributes'
    | 'discount_amount'
    | 'voucher_code'
    | 'quantity'
  >
) => {
  window.dataLayer.push({ ecommerce: null });
  pushEvent({
    Action: 'Browse',
    Label: 'Product Remove From Cart',
    event: 'remove_from_cart',
    ecommerce: {
      currency: product?.currency_type,
      value: Number(product?.price),
      items: {
        item_id: product?.pk,
        item_name: product?.name,
        affiliation: 'ZsaZsaZsu',
        coupon: product?.voucher_code,
        discount: product?.discount_amount,
        index: 0,
        item_brand: 'ZsaZsaZsu',
        ...(product?.attributes?.category_path &&
        product?.attributes?.category_path?.length > 0
          ? product?.attributes?.category_path
              .split(',')
              .reduce((acc, category, index) => {
                acc[`item_category${index + 1}`] = category.trim();
                return acc;
              }, {})
          : {}),
        item_list_id: product?.attributes?.ent_ProductHierarchyLevel01,
        item_list_name: product?.attributes?.ent_ProductHierarchyLevel02,
        item_variant: product?.attributes?.ent_ColorDescription,

        price: Number(product?.price),
        quantity: product?.quantity
      }
    }
  });
};

export const pushAddToWishlist = (
  favoriteItem: Pick<
    IProduct,
    | 'name'
    | 'pk'
    | 'price'
    | 'currency_type'
    | 'attributes'
    | 'discount_amount'
    | 'voucher_code'
    | 'quantity'
  >
) => {
  window.dataLayer.push({ ecommerce: null });
  pushEvent({
    Action: 'Browse',
    Label: 'Product Add to Wishlist',
    event: 'add_to_wishlish',
    ecommerce: {
      currency: favoriteItem?.currency_type,
      value: favoriteItem?.price,
      items: {
        item_id: favoriteItem?.pk,
        item_name: favoriteItem?.name,
        affiliation: 'ZsaZsaZsu',
        coupon: favoriteItem?.voucher_code,
        discount: favoriteItem?.discount_amount,
        index: 0,
        item_brand: 'ZsaZsaZsu',
        item_list_id: favoriteItem?.attributes?.ent_ProductHierarchyLevel01,
        item_list_name: favoriteItem?.attributes?.ent_ProductHierarchyLevel02,
        ...(favoriteItem?.attributes?.category_path &&
        favoriteItem?.attributes?.category_path?.length > 0
          ? favoriteItem?.attributes?.category_path
              .split(',')
              .reduce((acc, category, index) => {
                acc[`item_category${index + 1}`] = category.trim();
                return acc;
              }, {})
          : {}),
        item_variant: favoriteItem?.attributes?.ent_ColorDescription,
        price: favoriteItem?.price,
        quantity: 1
      }
    }
  });
};

export const pushCartView = (
  products: Pick<
    DataLayerProduct,
    | 'name'
    | 'pk'
    | 'price'
    | 'attributes'
    | 'quantity'
    | 'currency_type'
    | 'voucher_code'
    | 'discount_amount'
    | 'ent_ProductHierarchyLevel02'
  >[],
  total_amount: number
) => {
  window.dataLayer.push({ ecommerce: null });
  pushEvent({
    Action: 'Checkout',
    Label: 'view_cart',
    event: 'view_cart',
    Value: total_amount,
    ecommerce: {
      items: products?.map((product, item_index) => {
        const itemData = {
          item_id: product?.pk,
          item_name: product?.name,
          affiliation: 'ZsaZsaZsu',
          coupon: product?.voucher_code,
          discount: Number(product?.discount_amount),
          index: item_index,
          item_brand: 'ZsaZsaZsu',
          item_list_id: product?.attributes?.ent_ProductHierarchyLevel01,
          item_list_name: product?.attributes?.ent_ProductHierarchyLevel02,
          item_variant: product?.attributes?.ent_ColorDescription,
          price: Number(product?.price),
          quantity: product?.quantity,
          currency: product?.currency_type,
          value: product?.price
        };

        if (
          product?.attributes?.category_path &&
          product?.attributes?.category_path?.length > 0
        ) {
          const categoryList = product?.attributes?.category_path?.split(',');
          categoryList.forEach((item, index) => {
            itemData[`item_category${index + 1}`] = item.trim();
          });
        }
        return itemData;
      })
    }
  });
};

export const pushCheckoutStep = (
  action: string,
  products: Pick<
    DataLayerProduct,
    | 'name'
    | 'pk'
    | 'price'
    | 'attributes'
    | 'quantity'
    | 'currency_type'
    | 'voucher_code'
    | 'discount_amount'
  >[],
  total_amount: number
) => {
  window.dataLayer.push({ ecommerce: null });
  pushEvent({
    Action: action,
    Label: 'Begin Checkout',
    event: 'begin_checkout',
    Value: total_amount,
    ecommerce: {
      items: products?.map((product, item_index) => {
        const itemData = {
          item_id: product?.pk,
          item_name: product?.name,
          affiliation: 'ZsaZsaZsu',
          coupon: product?.voucher_code,
          discount: product?.discount_amount,
          index: item_index,
          item_brand: 'ZsaZsaZsu',
          item_list_id: product?.attributes?.ent_ProductHierarchyLevel01,
          item_list_name: product?.attributes?.ent_ProductHierarchyLevel02,
          item_variant: product?.attributes?.ent_ColorDescription,
          price: Number(product?.price),
          quantity: product?.quantity,
          currency: product?.currency_type,
          value: product?.price
        };

        if (
          product?.attributes?.category_path &&
          product?.attributes?.category_path?.length > 0
        ) {
          const categoryList = product?.attributes?.category_path?.split(',');
          categoryList.forEach((item, index) => {
            itemData[`item_category${index + 1}`] = item.trim();
          });
        }
        return itemData;
      })
    }
  });
};

export const pushPurchaseEvent = (
  action: string,
  products: Pick<
    IProduct,
    | 'name'
    | 'pk'
    | 'price'
    | 'attributes'
    | 'quantity'
    | 'currency_type'
    | 'voucher_code'
    | 'discount_amount'
    | 'product'
    | 'price_currency'
    | 'retail_price'
    | 'tax_rate'
  >[],
  total_amount: number,
  user: {
    email: string;
    phone: string;
  },
  order
) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  pushEvent({
    Action: 'Checkout',
    Label: 'Purchase',
    event: 'purchase',
    Value: order?.amount,
    ecommerce: {
      transaction_id: order?.number,
      value: order?.amount,
      shipping: order?.shipping_amount,
      currency: order?.currency?.label,
      coupon: order?.voucher_code,
      items: products?.map((product, item_index) => {
        const itemData = {
          item_id: product?.product?.pk,
          item_name: product?.product?.name,
          affiliation: 'ZsaZsaZsu',
          coupon: product?.product?.voucher_code,
          discount: product?.product?.discount_amount,
          index: item_index,
          item_brand: 'ZsaZsaZsu',
          item_list_id:
            product?.product?.attributes?.ent_ProductHierarchyLevel01,
          item_list_name:
            product?.product?.attributes?.ent_ProductHierarchyLevel02,
          item_variant: product?.product?.attributes?.ent_ColorDescription,
          price: product?.retail_price,
          quantity: product?.quantity,
          tax: product?.tax_rate,
          currency: product?.price_currency?.label
        };

        if (
          product?.product?.attributes?.category_path &&
          product?.product?.attributes?.category_path?.length > 0
        ) {
          const categoryList =
            product?.product?.attributes?.category_path?.split(',');
          categoryList.forEach((item, index) => {
            itemData[`item_category${index + 1}`] = item.trim();
          });
        }
        return itemData;
      }),
      user: {
        email: user?.email,
        phone: user?.phone
      }
    }
  });
};

export const PushAddPaymentInfo = (
  action: string,
  products: Pick<
    IProduct,
    | 'name'
    | 'pk'
    | 'price'
    | 'attributes'
    | 'quantity'
    | 'currency_type'
    | 'voucher_code'
    | 'discount_amount'
    | 'product'
  >[],
  preOrderInfo
) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  pushEvent({
    Action: 'purchase',
    Label: 'Add Payment Info',
    event: 'add_payment_info',
    Value: preOrderInfo?.total_amount,
    ecommerce: {
      currency: preOrderInfo.currency_type_label,
      value: preOrderInfo.total_amount,
      payment_type: preOrderInfo?.payment_option?.payment_type_label,
      items: products?.map((product, item_index) => {
        const itemData = {
          item_id: product?.pk,
          item_name: product?.product?.name,
          affiliation: 'ZsaZsaZsu',
          coupon: product?.product?.voucher_code,
          discount: product?.product?.discount_amount,
          index: item_index,
          item_brand: 'ZsaZsaZsu',
          item_list_id:
            product?.product?.attributes?.ent_ProductHierarchyLevel01,
          item_list_name:
            product?.product?.attributes?.ent_ProductHierarchyLevel02,
          item_variant: product?.product?.attributes?.ent_ColorDescription,
          price: product?.product?.price,
          quantity: product?.quantity,
          currency: product?.currency_type,
          value: product?.product?.price
        };

        if (
          product?.attributes?.category_path &&
          product?.attributes?.category_path?.length > 0
        ) {
          const categoryList = product?.attributes?.category_path?.split(',');
          categoryList.forEach((item, index) => {
            itemData[`item_category${index + 1}`] = item.trim();
          });
        }
        return itemData;
      })
    }
  });
};

export const pushShippingInformation = (
  action: string,
  products: Pick<
    IProduct,
    | 'name'
    | 'pk'
    | 'price'
    | 'attributes'
    | 'quantity'
    | 'currency_type'
    | 'voucher_code'
    | 'discount_amount'
    | 'product'
  >[],
  total_amount: number,
  preOrder
) => {
  window.dataLayer.push({ ecommerce: null });
  pushEvent({
    Action: action,
    Label: 'add_shipping_info',
    event: 'add_shipping_info',
    Value: preOrder?.total_amount,
    ecommerce: {
      currency: preOrder?.currency_type_label,
      value: preOrder?.total_amount,
      coupon: preOrder?.voucher_code,
      shipping_tier: preOrder?.shipping_option?.name,
      items: products?.map((product, item_index) => {
        const itemData = {
          item_id: product?.product?.pk,
          item_name: product?.product?.name,
          affiliation: 'ZsaZsaZsu',
          coupon: product?.product?.voucher_code,
          discount: product?.product?.discount_amount,
          index: item_index,
          item_brand: 'ZsaZsaZsu',
          item_list_id:
            product?.product?.attributes?.ent_ProductHierarchyLevel01,
          item_list_name:
            product?.product?.attributes?.ent_ProductHierarchyLevel02,
          item_variant: product?.product?.attributes?.ent_ColorDescription,
          price: Number(product?.product?.price),
          quantity: product?.quantity,
          currency: product?.product?.currency_type,
          value: product?.product?.price
        };

        if (
          product?.attributes?.category_path &&
          product?.attributes?.category_path?.length > 0
        ) {
          const categoryList = product?.attributes?.category_path?.split(',');
          categoryList.forEach((item, index) => {
            itemData[`item_category${index + 1}`] = item.trim();
          });
        }
        return itemData;
      })
    }
  });
};

export const pushProductListProductViewed = (
  products: Pick<
    IProduct,
    | 'name'
    | 'pk'
    | 'price'
    | 'quantity'
    | 'attributes'
    | 'discount_amount'
    | 'voucher_code'
    | 'currency_type'
  >[],
  category
) => {
  window.dataLayer.push({
    event: 'view_item_list',
    ecommerce: {
      items: products?.map((product, item_index) => {
        const itemData = {
          item_id: product?.pk,
          item_name: product?.name,
          affiliation: 'ZsaZsaZsu',
          coupon: product?.voucher_code,
          discount: product?.discount_amount,
          index: item_index,
          item_brand: 'ZsaZsaZsu',
          item_list_id: category?.pk,
          item_list_name: product?.attributes?.ent_ProductHierarchyLevel02,
          item_variant: product?.attributes?.ent_ColorDescription,
          price: Number(product?.price),
          quantity: product?.quantity,
          currency: product?.currency_type,
          value: product?.price
        };

        if (
          product?.attributes?.category_path &&
          product?.attributes?.category_path?.length > 0
        ) {
          const categoryList = product?.attributes?.category_path?.split(',');
          categoryList.forEach((item, index) => {
            itemData[`item_category${index + 1}`] = item.trim();
          });
        }
        return itemData;
      })
    }
  });
};

export const pushProductClicked = (
  product: Pick<
    DataLayerProduct,
    'name' | 'pk' | 'price' | 'attributes' | 'discount_amount' | 'voucher_code'
  >
) => {
  window.dataLayer.push({ ecommerce: null });
  pushEvent({
    Action: 'Browse',
    Label: 'select_item',
    ecommerce: {
      item_list_id: product?.attributes?.ent_ProductHierarchyLevel01,
      item_list_name: product?.attributes?.ent_ProductHierarchyLevel02,
      items: [
        {
          item_id: product?.pk,
          item_name: product?.name,
          affiliation: 'ZsaZsaZsu',
          coupon: product?.voucher_code,
          discount: Number(product?.discount_amount),
          index: 0,
          item_brand: 'ZsaZsaZsu',
          ...(product?.attributes?.category_path &&
          product?.attributes?.category_path?.length > 0
            ? product?.attributes?.category_path
                .split(',')
                .reduce((acc, category, index) => {
                  acc[`item_category${index + 1}`] = category.trim();
                  return acc;
                }, {})
            : {}),
          item_list_id: product?.attributes?.ent_ProductHierarchyLevel01,
          item_list_name: product?.attributes?.ent_ProductHierarchyLevel02,
          item_variant: product?.attributes?.ent_ColorDescription,
          price: product?.price,
          quantity: 1
        }
      ]
    },
    event: 'select_item'
  });
};

export const pushProductViewed = (
  product: Pick<
    DataLayerProduct,
    | 'pk'
    | 'sku'
    | 'name'
    | 'price'
    | 'currency_type'
    | 'attributes'
    | 'discount_amount'
    | 'voucher_code'
  >
) => {
  window.dataLayer.push({ ecommerce: null });
  pushEvent({
    Action: 'Browse',
    Label: 'view_item',
    ecommerce: {
      currency: product?.currency_type,
      value: product?.price,
      items: [
        {
          item_id: product?.pk,
          item_name: product?.name,
          affiliation: 'ZsaZsaZsu',
          coupon: product?.voucher_code,
          discount: product?.discount_amount,
          index: 0,
          item_brand: 'ZsaZsaZsu',
          ...(product?.attributes?.category_path &&
          product?.attributes?.category_path?.length > 0
            ? product?.attributes?.category_path
                .split(',')
                .reduce((acc, category, index) => {
                  acc[`item_category${index + 1}`] = category.trim();
                  return acc;
                }, {})
            : {}),
          item_list_id: product?.attributes?.ent_ProductHierarchyLevel01,
          item_list_name: product?.attributes?.ent_ProductHierarchyLevel02,
          item_variant: product?.attributes?.ent_ColorDescription,
          price: product?.price,
          quantity: 1
        }
      ]
    },
    event: 'view_item'
  });
};

export const pushLogin = () => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'login',
    loginMethod: 'Google'
  });
};

export const pushLogout = () => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'Logout',
    loginMethod: 'Google'
  });
};

export const pushSignup = () => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: 'Signup',
    loginMethod: 'Google'
  });
};

export const pushUserData = ({pk, hashedEmail, hashedPhone}) => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    Label: 'user_data',
    user: {
      id: pk,
      email: hashedEmail || '',
      phone: hashedPhone || '',
    }
  });
};

export const pushEvent = (event: IGTMEvent) => {
  const { Category = 'Enhanced Ecommerce', Value = 0, ...rest } = event;

  window.dataLayer.push({ Category, Value, ...rest });
};

export const pushViewSearchResult = (searchedText: string) => {
  window.dataLayer = window?.dataLayer || [];

  window.dataLayer.push({
    event: 'view_search_result',
    search_term: searchedText
  });
}